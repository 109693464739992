import '../styles/index.css';
import Nav from './Nav';
import Home from './Home'
import About from './About'
import Works from './Works'
import Contact from './Contact'
import WIP from './WIP'

function App() {
  return (
    <div>
      <article className='scroller'>
          {/* <Nav/>
          <Home/>
          <About/>
          <Works/>
          <Contact/> */}
          <WIP />
      </article>
      {/* <footer>
          <nav>
              <a href='https://www.github.com/cosmos1255' target='_blank' rel="noreferrer">GitHub</a>
              <a href='https://www.linkedin.com/in/david-eyrich' target='_blank' rel="noreferrer">LinkedIn</a>
          </nav>
      </footer> */}
    </div>
  );
}

export default App;
