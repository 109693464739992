import '../styles/index.css'

function WIP() {
    return (
        <section id="wip">
            <h1>Welcome!</h1>
            <p>This site is currently under construction.</p>
        </section>
    )
}

export default WIP